@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-tooltip {
    left: 0 !important
}

.ql-editor {
    min-height: 100px;
}

.ql-container.ql-snow {
    border-radius: 6px;
    margin-top: 5px;
    border-top: 1px solid #ccc !important;
}

.ql-container.ql-snow:hover {
    border: 1px solid #60a5fa;
    border-top: 1px solid #60a5fa !important;
}

.ql-container.ql-snow:focus-within {
    border: 1px solid #3b82f6;
    border-top: 1px solid #3b82f6 !important;
}

.ql-editor.ql-blank {
    outline: none;
}

.ql-toolbar.ql-snow {
    border: none !important;
    padding: 8px 0px !important;
}

.ql-link,
.ql-italic,
.ql-bold,
.ql-underline {
    background: #f3f4f6 !important;
    width: 68px !important;
    height: 55px !important;
    border-radius: 10px;
    margin-right: 5px;
    padding: 15px 21px !important;
}

.ql-link:hover,
.ql-italic:hover,
.ql-bold:hover,
.ql-underline:hover {
    background: #eff6ff !important;
}

.ql-link:active,
.ql-italic:active,
.ql-bold:active,
.ql-underline:active {
    background: #bfdbfe !important;
}

.ql-active svg * {
    stroke: white !important
}

.ql-active:hover {
    background: black !important;
}

.ql-active {
    background: black !important;
}

.pagination {
    display: flex;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
}

.page-link {
    padding-left: 0.65rem;
    padding-right: 0.65rem;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
}

.page-item {
    padding-top: 3px;
    padding-bottom: 3px;
    border-right: 1px solid #e5e7eb;
}

.page-item:hover {
    background-color: #f3f6fc;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.page-item.disabled:hover {
    background-color: transparent;
}

.page-item.disabled>.page-link {
    color: #8f9196;
    cursor: default
}

.page-item.active {
    background-color: #e5e7eb;
}

.page-item:last-child {
    border-right: 0px;
}





.rdrDateDisplayItemActive input {
    color: #1D4ED8 !important;
  }
  
  .rdrDateDisplay {
    color: #1D4ED8 !important;
  }
  
  .rdrInRange {
    color: #1D4ED8 !important;
  }
  
  .rdrDay {
    color: #1D4ED8 !important;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    color: #1D4ED8 !important;
  }
  
  .rdrDayWeekend .rdrDayEndOfWeek {
    color: #1D4ED8 !important;
  }
  
  .rdrStartEdge {
    color: #1D4ED8 !important;
  }
  
  .rdrEndEdge {
    color: #1D4ED8 !important;
  }
  
  .rdrDayToday .rdrDayNumber span:after {
    background: #1D4ED8 !important;
  }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: #ffffff !important;
  }
  
  .rdrDayHovered {
    border-color: green;
  }

  .rdrDefinedRangesWrapper{
    display: none !important;
  }

  .rdrCalendarWrapper{
    width: 100% !important;
  }
  .rdrMonths{
    width: 100% !important;
  }
  .rdrMonthsVertical{
    width: 100% !important;

  }
  .rdrMonth{
    width: 100% !important;
    padding-bottom: 0 !important;
  }